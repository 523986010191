import { AntDesign } from '@expo/vector-icons';
import { makeRedirectUri, useAuthRequest, useAutoDiscovery } from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import {
  Box,
  Button,
  Center,
  Hidden,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  StatusBar,
  Text,
  useToast,
  VStack,
} from 'native-base';
import React, { useEffect } from 'react';
import Logo from './components/logo.png';
import SignInForm from './components/SignInForm';

WebBrowser.maybeCompleteAuthSession();

// const discoveryTwitch = {
//   authorizationEndpoint: 'https://id.twitch.tv/oauth2/authorize',
//   tokenEndpoint: 'https://id.twitch.tv/oauth2/token',
//   revocationEndpoint: 'https://id.twitch.tv/oauth2/revoke',
// };
// const clientId = 'hsmykmjyui2cpnsemq08pinihya2d6';

// const discovery = {
//   authorizationEndpoint: 'https://steamcommunity.com/oauth2/authorize',
//   tokenEndpoint: 'https://steamcommunity.com/oauth2/token',
//   revocationEndpoint: 'https://steamcommunity.com/oauth2/revoke',
// };
const clientId = 'DBACF46DB23520FCF01B61CA59F49D0F';

export default function SignIn(props: any) {
  const discovery = useAutoDiscovery('http://192.168.0.139:8082');
  console.log({ discovery });
  const [request, response, promptAsync] = useAuthRequest(
    {
      // responseType: ResponseType.Token,
      clientId,
      redirectUri: makeRedirectUri(),
      scopes: ['openid', 'profile', 'offline_access'],
    },
    discovery
  );

  const toast = useToast();

  useEffect(() => {
    toast.show({ description: `default schema: ${makeRedirectUri()}` });
    toast.show({ description: `schema: ${makeRedirectUri({ scheme: 'klan' })}` });
  }, []);

  useEffect(() => {
    if (response?.type === 'success') {
      const { code } = response.params;
      toast.show({ description: JSON.stringify(response) });
      console.log({ code });
    } else if (response) {
      toast.show({ description: response });
    }
  }, [response]);

  const signIn = () => {
    promptAsync();
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="light-content" />
      <Box safeAreaTop _light={{ bg: 'primary.900' }} _dark={{ bg: 'coolGray.900' }} />
      <Center
        my="auto"
        _dark={{ bg: 'coolGray.900' }}
        _light={{ bg: 'primary.900' }}
        flex="1"
        p={{ md: 8 }}>
        <Stack
          flexDirection={{ base: 'column', md: 'row' }}
          w="100%"
          maxW={{ md: '1016px' }}
          flex={{ base: '1', md: 'none' }}>
          <Hidden from="md">
            <VStack px="4" mt="4" mb="5" space="9">
              <HStack space="2" alignItems="center">
                <IconButton
                  variant="unstyled"
                  pl="0"
                  disabled={!request}
                  onPress={() => {}}
                  icon={<Icon size="6" as={AntDesign} name="arrowleft" color="coolGray.50" />}
                />
                <Text color="coolGray.50" fontSize="lg">
                  Sign In
                </Text>
              </HStack>
              <VStack space="2">
                <Text fontSize="3xl" fontWeight="bold" color="coolGray.50">
                  Welcome back,
                </Text>
                <Text
                  fontSize="md"
                  fontWeight="normal"
                  _dark={{
                    color: 'coolGray.400',
                  }}
                  _light={{
                    color: 'primary.300',
                  }}>
                  Sign in to continue
                </Text>
              </VStack>
            </VStack>
          </Hidden>
          <Hidden till="md">
            <Center
              flex="1"
              bg="primary.700"
              borderTopLeftRadius={{ base: '0', md: 'xl' }}
              borderBottomLeftRadius={{ base: '0', md: 'xl' }}>
              <Image
                h="24"
                size="80"
                alt="NativeBase Startup+ "
                resizeMode="contain"
                source={Logo}
              />
            </Center>
          </Hidden>
          <Button onPress={signIn}>Twitch Login</Button>
          <SignInForm props={props} />
        </Stack>
      </Center>
    </>
  );
}
